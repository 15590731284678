import Card from "../../components/ui/Card";
import Carroussel from "../../components/ui/Carousel";
import { LANGUAGE } from "../../constants/localStorage";
import { getData } from "../../services/storage";
import { getLocaleString } from "../../utils/locales";



export default function GoldConvenience() {
  const locale = getData(LANGUAGE);
  let cards = [
    {
      key: 1,
      content: (
        <Card title={getLocaleString('save_in_gold', locale)} description={getLocaleString('save_in_gold_description', locale)} />
      )
    },
    {
      key: 2,
      content: (
        <Card title={getLocaleString('gold_official_market_price', locale)} description={getLocaleString('gold_official_market_price_description', locale)} />
      )
    },
    {
      key: 3,
      content: (
        <Card title={getLocaleString('physical_gold', locale)} description={getLocaleString('redeem_physical_gold_description', locale)} />
      )
    },
    {
      key: 4,
      content: (
        <Card title={getLocaleString('secure_feature_in_gold', locale)} description={getLocaleString('secure_feature_in_gold_description', locale)} />
      )
    },
    {
      key: 5,
      content: (
        <Card title={getLocaleString('save_in_gold', locale)} description={getLocaleString('save_in_gold_description', locale)} />
      )
    },
    {
      key: 6,
      content: (
        <Card title={getLocaleString('gold_official_market_price', locale)} description={getLocaleString('gold_official_market_price_description', locale)} />
      )
    },
    {
      key: 7,
      content: (
        <Card title={getLocaleString('physical_gold', locale)} description={getLocaleString('redeem_physical_gold_description', locale)} />
      )
    },
    {
      key: 8,
      content: (
        <Card title={getLocaleString('secure_feature_in_gold', locale)} description={getLocaleString('secure_feature_in_gold_description', locale)} />
      )
    },
  ];
  return (
      <div className="pt-16">
        <div style={{padding: "5px", marginTop: "20px"}}>
               <div className="text-center text-4xl md:text-6xl font-semibold text-[#010103]/80 space-y-4">
                 <p>{getLocaleString('the_gold', locale)}</p> 
                 <p>{getLocaleString('convenience', locale)}</p>
               </div>
               <p className="py-4 text-center text-xl font-normal text-[#010103]/50">
                 {getLocaleString('gold_convenience_line1', locale)} <br /> {getLocaleString('gold_convenience_line2', locale)} <br />
                 {getLocaleString('gold_convenience_line3', locale)} <br /> {getLocaleString('gold_convenience_line4', locale)}
               </p>
             </div>
      <Carroussel
        cards={cards}
        height="500px"
        width="30%"
        margin="0 auto"
        offset={2}
        showArrows={true}
      />
    </div>
    // <div className='' style={{}}>
    
    //   <Parallax pages={3} >
    //       <ParallaxLayer
    //         offset={1}
    //         speed={1}
    //         // style={{ ...alignCenter, paddingRight: '100px', paddingLeft: '100px' }}
    //       >
    //         <div className="flex justify-start pt-20 px-9 md:px-24 lg:px-96">
    //           <div className="py-16 w-50 h-80 md:w-72 rounded-xl bg-gradient-to-r from-[#e9be5a] to-[#c6952c]">
    //             <p className="text-center text-3xl font-semibold text-[#010103]/80 ">{getLocaleString('save_in_gold', locale)}</p>
    //             <p className="p-4 text-center text-xl font-normal text-[#010103]/50">
    //             {getLocaleString('save_in_gold_description', locale)}
    //             </p>
    //           </div>
    //         </div>

    //         <div className="flex justify-end pt-20 px-9 md:px-24 lg:px-96">
    //           <div className="py-16 h-80 w-50 md:w-72 rounded-xl bg-gradient-to-r from-[#e9be5a] to-[#c6952c]">
    //             <p className="text-center text-3xl font-semibold text-[#010103]/80 px-4">
    //               {getLocaleString('gold_official_market_price', locale)}
    //             </p>
    //             <p className="p-4 text-center text-xl font-normal text-[#010103]/50">
    //               {getLocaleString('gold_official_market_price_description', locale)}
    //             </p>
    //           </div>
    //         </div>

    //         <div className="flex items-start justify-start py-20 px-9 md:px-24 lg:items-center lg:justify-center lg:px-96">
    //           {/* <div className="py-16 h-80 w-72 rounded-xl bg-gradient-to-r from-[#e9be5a] to-[#c6952c]">
    //             <p className="text-center text-3xl font-semibold text-[#010103]/80">
    //               Secure Future <br /> in Gold
    //             </p>
    //             <p className="p-4 text-center text-xl font-normal text-[#010103]/50">
    //               Increasing gold value over time ensures your investments and capital gain
    //             </p>
    //           </div> */}
    //         </div>

    //         <div className="flex justify-start px-9 md:px-24 lg:px-96">
    //           <div className="py-16 h-80 w-72 rounded-xl bg-gradient-to-r from-[#e9be5a] to-[#c6952c]">
    //             <p className="text-center text-3xl font-semibold text-[#010103]/80">
    //               {getLocaleString('redeem', locale)} <br /> {getLocaleString('physical_gold', locale)}
    //             </p>
    //             <p className="p-4 text-center text-xl font-normal text-[#010103]/50">
    //               {getLocaleString('redeem_physical_gold_description', locale)}
    //             </p>
    //           </div>
    //         </div>
    //         <div className="flex justify-end pt-20 px-9 md:px-24 lg:px-96">
    //           <div className="py-16 h-80 w-72 rounded-xl bg-gradient-to-r from-[#e9be5a] to-[#c6952c]">
    //             <p className="text-center text-3xl font-semibold text-[#010103]/80">
    //               {getLocaleString('secure_feature', locale)} <br /> {getLocaleString('in_gold', locale)}
    //             </p>
    //             <p className="p-4 text-center text-xl font-normal text-[#010103]/50">
    //               {getLocaleString('secure_feature_in_gold_description', locale)}
    //             </p>
    //           </div>
    //         </div>
    //       </ParallaxLayer>
    //     </Parallax>
    //       <div
    //       // sticky={{ start: 0, end: 1 }}
    //       style={{ justifyContent: 'center', zIndex: -1, position:'sticky' }}
    //     >
    //       <span className="flex items-center justify-center py-80 px-9 md:px-24 lg:px-28">
    //         <div>
    //           <p className="text-center text-4xl md:text-6xl font-semibold text-[#010103]/80">
    //             {getLocaleString('the_gold', locale)} <br />
    //             {getLocaleString('convenience', locale)}
    //           </p>
    //           <p className="py-4 text-center text-xl font-normal text-[#010103]/50">
    //             {getLocaleString('gold_convenience_line1', locale)} <br /> {getLocaleString('gold_convenience_line2', locale)} <br />
    //             {getLocaleString('gold_convenience_line3', locale)} <br /> {getLocaleString('gold_convenience_line4', locale)}
    //           </p>
    //         </div>
    //       </span>
    //     </div>
    // </div>
  );
}
